/*
  For more details on how to customize theme -- https://chakra-ui.com/docs/theming/customize-theme
 */

import { extendTheme } from '@chakra-ui/react';
import { Button, Heading, Text, Container } from './components';
import { textStyles, globalStyles } from './styles';
import { colors } from './colors';
import { fonts } from './fonts';

const { styles: customGlobalStyles } = globalStyles;

const theme = extendTheme({
  textStyles,
  customGlobalStyles,
  colors,
  fonts,
  components: {
    Button,
    Heading,
    Text,
    Container,
  },
});

export default theme;
