export const fonts = {
  heading: 'Mont-Heavy',
  body: `Nexa, 
        Lucida-Grande, 
        system-ui, 
        -apple-system, 
        BlinkMacSystemFont,
        "Segoe UI", 
        Roboto, 
        "Helvetica Neue", 
        sans-serif`,
};
