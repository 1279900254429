// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commercial-about-us-js": () => import("./../../../src/pages/commercial/about-us.js" /* webpackChunkName: "component---src-pages-commercial-about-us-js" */),
  "component---src-pages-commercial-contact-us-js": () => import("./../../../src/pages/commercial/contact-us.js" /* webpackChunkName: "component---src-pages-commercial-contact-us-js" */),
  "component---src-pages-commercial-index-js": () => import("./../../../src/pages/commercial/index.js" /* webpackChunkName: "component---src-pages-commercial-index-js" */),
  "component---src-pages-commercial-projects-js": () => import("./../../../src/pages/commercial/projects.js" /* webpackChunkName: "component---src-pages-commercial-projects-js" */),
  "component---src-pages-commercial-services-capital-projects-js": () => import("./../../../src/pages/commercial/services/capital-projects.js" /* webpackChunkName: "component---src-pages-commercial-services-capital-projects-js" */),
  "component---src-pages-commercial-services-carpentry-js": () => import("./../../../src/pages/commercial/services/carpentry.js" /* webpackChunkName: "component---src-pages-commercial-services-carpentry-js" */),
  "component---src-pages-commercial-services-maintenance-js": () => import("./../../../src/pages/commercial/services/maintenance.js" /* webpackChunkName: "component---src-pages-commercial-services-maintenance-js" */),
  "component---src-pages-commercial-services-painting-js": () => import("./../../../src/pages/commercial/services/painting.js" /* webpackChunkName: "component---src-pages-commercial-services-painting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-residential-about-us-js": () => import("./../../../src/pages/residential/about-us.js" /* webpackChunkName: "component---src-pages-residential-about-us-js" */),
  "component---src-pages-residential-contact-us-js": () => import("./../../../src/pages/residential/contact-us.js" /* webpackChunkName: "component---src-pages-residential-contact-us-js" */),
  "component---src-pages-residential-index-js": () => import("./../../../src/pages/residential/index.js" /* webpackChunkName: "component---src-pages-residential-index-js" */),
  "component---src-pages-residential-projects-js": () => import("./../../../src/pages/residential/projects.js" /* webpackChunkName: "component---src-pages-residential-projects-js" */),
  "component---src-pages-residential-services-handyman-work-js": () => import("./../../../src/pages/residential/services/handyman-work.js" /* webpackChunkName: "component---src-pages-residential-services-handyman-work-js" */),
  "component---src-pages-residential-services-new-construction-js": () => import("./../../../src/pages/residential/services/new-construction.js" /* webpackChunkName: "component---src-pages-residential-services-new-construction-js" */),
  "component---src-pages-residential-services-remodeling-js": () => import("./../../../src/pages/residential/services/remodeling.js" /* webpackChunkName: "component---src-pages-residential-services-remodeling-js" */),
  "component---src-pages-residential-services-windows-and-doors-js": () => import("./../../../src/pages/residential/services/windows-and-doors.js" /* webpackChunkName: "component---src-pages-residential-services-windows-and-doors-js" */)
}

