export const Button = {
  // The styles all button have in common
  baseStyle: {
    // fontWeight: 'bold',
    borderRadius: '4px',
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: '15px',
      padding: '16px',
    },
    md: {
      fontSize: '15px',
      paddingX: '25px',
    },
    lg: {
      fontSize: '21px',
      paddingX: '25px',
    },
  },
  // Two variants: outline and solid
  variants: {
    primary: {
      bg: 'secondary',
      color: 'white',
    },
    light: {
      bg: 'white',
      color: 'gray',
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
};
