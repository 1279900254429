/*

 • For more details on how to customize theme -- https://chakra-ui.com/docs/theming/customize-theme
 • Text styles -- https://chakra-ui.com/docs/features/text-and-layer-styles

 Semantic naming system that corresponds to respective html tags in production 
 (caption, paragraph, h1, h2, or custom by specific component)

 */

export const textStyles = {
  h1: {
    fontFamily: 'Mont-Heavy',
    fontSize: { base: '28px', md: '52px' },
    lineHeight: '52px',
    letterSpacing: '1.6px',
  },
  h2: {
    fontFamily: 'Mont-Heavy',
    fontSize: { base: '26px', md: '35px' },
    lineHeight: { base: '33px', md: '40px' },
    letterSpacing: { base: '0.9px', md: '1.14px' },
  },
  p: {
    fontFamily: 'Nexa',
    fontSize: { base: '15px', md: '18px' },
    lineHeight: { base: '22px', md: '26px' },
    letterSpacing: { base: '0.4px', md: '0.4px' },
    textAlign: 'justify',
    textJustify: 'inner-word',
  },
  pFooter: {
    fontFamily: 'Nexa',
    fontSize: '15px',
    lineHeight: '25px',
    letterSpacing: '2px',
  },
  pBold: {
    fontFamily: 'Nexa',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '1.5px',
  },
  heroDescription: {
    fontFamily: 'Nexa',
    fontSize: { base: '14px', md: '27.6px' },
    lineHeight: { base: '20px', md: '33.6px' },
    letterSpacing: { base: '0.29px', md: '0.9px' },
  },
  primaryButtonText: {
    fontFamily: 'Nexa',
    fontWeight: 'bold',
    fontSize: { base: '21px' },
    letterSpacing: { base: '1px' },
  },
  pProjectText: {
    fontFamily: 'Nexa',
    fontWeight: 'bold',
    fontSize: { base: '11.5px', md: '20px' },
    letterSpacing: '0.38px',
    textAlign: 'justify',
    textJustify: 'inner-word',
  },
  mProjectText: {
    fontFamily: 'Nexa',
    fontWeight: 'bold',
    fontSize: { base: '12.8px', md: '16px' },
    letterSpacing: '0.8px',
    textAlign: 'justify',
    textJustify: 'inner-word',
  },
  servicePageHeading: {
    fontFamily: 'Mont-Heavy',
    fontSize: { base: '28px', md: '35px' },
    lineHeight: '1.14',
    letterSpacing: '1.14px',
  },
  servicePageText: {
    fontFamily: 'Nexa',
    fontSize: { base: '16px', md: '18px' },
    letterSpacing: '0.8px',
    textAlign: 'justify',
    textJustify: 'inner-word',
  },
};
